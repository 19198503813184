.root {
    --app-height: 100vh;
    --headerFullHeight: 0;
}

.primaryButton {
    margin-block-start: var(--spacingUnits-s);
}

.title {
    display: block;
}

.s .requestId {
    font-size: var(--fonts-sizes-body2);
}
