.container {
    margin-top: var(--spacingUnits-l);
}

.surgeIcon {
    background-size: contain;
    height: 44.5px;
    width: 44.5px;
    color: #c044f2;
}

.surgeIcon.s {
    color: #faf9f7;
    background-color: #c044f2;
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.surgeIcon:not(.s) {
    margin-inline-start: -5px;
    margin-inline-end: -6px;
    margin-block-end: 1px;
}

.surgeIcon:not(.s) .icon {
    height: 100%;
    width: 100%;
}

.description {
    background: var(--colorScheme-bgMinor);
    padding: var(--spacingUnits-l);
    border-radius: 24px;
    display: flex;
    width: 100%;
}

.description.s {
    padding: unset;
    background: unset;
    flex-direction: column;
}

.note {
    max-width: 536px;
}
