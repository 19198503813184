.root {
    background: var(--colorScheme-background);
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 398px;
    padding: var(--spacingUnits-l);
    border-radius: 24px;
}

.textCenter {
    text-align: center;
    display: block;
}

.footerCard {
    margin-top: var(--spacingUnits-m);
}

@media (max-width: 600px) {
    .root {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        flex-grow: 1;
        max-width: 100%;
        width: 100%;
    }

    .footerCard {
        border-radius: 0;
        margin-top: 0;
        padding-top: 0;
    }
}
