.Page {
    width: 100%;
    min-width: 1024px;
    box-sizing: border-box;
    padding: 28px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 1176px;
    margin-left: auto;
    margin-right: auto;
}

.Page.full {
    min-height: 100vh;
    justify-content: center;
}

.amber-section {
    position: relative;
}
