.item {
    margin-block-start: 2px;
    width: 100%;
}

.item.s {
    border-top: 1px solid var(--components-listItems-divider);
}

.price {
    white-space: nowrap;
}
