.container {
    display: flex;
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
}

.container.ie {
    display: block;
}

.button {
    border-radius: 4px;
    padding: 8px 22px;
    font-weight: 500;
    min-width: 210px;
}

.button span {
    color: #21211f;
    width: 100%;
    display: inherit;
    font-size: 14px;
    font-style: normal;
    align-items: inherit;
    font-weight: 500;
    line-height: 20px;
    text-transform: none;
    justify-content: inherit;
}

.wrapper {
    align-self: center;
    position: relative;
    flex-direction: column;
}

.mainContainer {
    display: block;
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
}

.mainContainerMobile {
    flex-grow: 0;
    min-width: 300px;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
}

.main {
    background: #f3f0e8;
    border-radius: 96px;
    padding: calc(var(--spacingUnits-s) * 12) calc(var(--spacingUnits-s) * 7);
    width: 100%;
}

.main > div {
    min-height: 100%;
}

.main h1 {
    font-weight: 500;
    margin-bottom: calc(var(--spacingUnits-s) * 2);
    max-width: 510px;
    word-spacing: 0;
}

.main h4 {
    line-height: 32px;
    margin-bottom: calc(var(--spacingUnits-s) * 3);
    margin-top: calc(var(--spacingUnits-s) * 4);
    max-width: 420px;
}

.main h4 b {
    white-space: nowrap;
}

.hint {
    margin-top: calc(var(--spacingUnits-s) * 6);
    max-width: 320px;
    display: block;
    font-size: 14px;
    color: #75736f;
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 600px) {
    .container {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media (max-width: 600px) {
    .container {
        padding: 0;
    }

    .wrapper {
        flex-direction: column-reverse;
    }

    .main {
        border-radius: 0;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        padding: calc(var(--spacingUnits-s) * 3) calc(var(--spacingUnits-s) * 3);
        position: relative;
        z-index: 1;
    }

    .main h1 {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 16px;
        max-width: 300px;
    }

    .main h4 {
        font-size: 18px;
        line-height: 20px;
    }

    .hint {
        margin-top: 24px;
    }
}
