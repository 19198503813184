.dateLabel {
    padding-top: 13px;
}

.timeLabel {
    padding-top: 12px;
}

.icon {
    margin-inline-end: 10px;
}

.timeInput {
    width: 100%;
}

.blockTime {
    padding-block-start: 10px;
}
