.group {
    background: var(--colorScheme-bgMinor);
    border-radius: 24px;
}

.group.s {
    margin-inline-start: calc(-1 * var(--spacingUnits-l));
    margin-inline-end: calc(-1 * var(--spacingUnits-l));
}

.group.s + .group.s {
    margin-block-start: var(--spacingUnits-xs);
}

.island {
    background: var(--colors-absolute-white);
    border-radius: 24px;
}

.islandPaper {
    border-radius: 24px;
    padding: 16px;
}
