.input {
    padding-inline-end: 28px !important;
}

.inputRoot {
    border: none !important;
}

.phoneInput {
    width: 100%;
}

.textColor {
    color: var(--colorScheme-text);
}

.name {
    max-width: 180px;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.noML.noML {
    margin-inline-start: unset;
}

.trail.trail > div {
    place-content: unset !important;
    margin-inline-end: unset;
    width: var(--components-slots-size-s);
}

.errorMessage {
    margin-inline-start: calc(var(--spacingUnits-l) / 2);
    margin-block-end: calc(var(--spacingUnits-l) / 2);
}
