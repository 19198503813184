.ControlGroup {
    display: flex;

    > * {
        margin-left: $unit;
        margin-right: $unit;
    }

    [dir=ltr] & {
        > *:last-child {
            margin-right: 0;
        }

        > *:first-child {
            margin-left: 0;
        }
    }

    [dir=rtl] & {
        > *:last-child {
            margin-left: 0;
        }

        > *:first-child {
            margin-right: 0;
        }
    }
}
