.radio {
    background-color: rgba(92, 90, 87, 0.1);
}

.option.s {
    min-width: 178px;
}

.optionContainer {
    max-width: 50%;
}
