.Tariff {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: var(--colorScheme-controlMinor);
    border-radius: 12px;
    cursor: pointer;
    position: relative;
    flex: 1;
    outline: 0;
    text-align: start;
    word-break: keep-all;
    height: 100%;
    min-width: 150px;
}

.Tariff__wrapper {
    height: 100%;
}

.Tariff_error {
    border: solid 1px #fa3e2c;
}

.Tariff__image {
    width: 57px;
    height: 32px;
    background: no-repeat center;
    background-size: 100%;
}

.Tariff__image_type_courier {
    background-image: url('images/courier.png');
}

.Tariff__image_type_car {
    background-image: url('images/car.png');
}

.Tariff__image_type_d2d-slow {
    background-image: url('images/d2d-slow.png');
}

.Tariff__image_type_cargo {
    background-image: url('images/cargo2.png');
}

.Tariff__image_lang_he {
    transform: rotateY(180deg);
}

.Tariff__image_type_courier.Tariff__image_country_isr {
    width: 60px;
    height: 50px;
    background-image: url('images/isr/express.png');
}

.Tariff__text {
    color: rgba(33, 32, 31, 0.5);
}

.Tariff__caption {
    width: 75%;
}

.Tariff__button {
    width: 100%;
}

.Tariff__radio {
    position: absolute;
    bottom: 6px;
    right: 6px;
}
