.TransferInfo {
    margin-left: -24px;
    margin-right: -24px;
}

.TransferInfo .amber-list-item {
    padding-left: 24px;
    padding-right: 24px;
}

.TransferInfo__content {
    display: flex;
    flex-direction: column;
}

.TransferInfo__content .amber-list-item-content__title {
    order: 1;
    white-space: normal;
}

.TransferInfo__content .amber-list-item-content__description {
    order: 0;
}

.TransferInfo__do-order .amber-list-item-content__title {
    white-space: normal;
    text-align: center;
}

.TransferInfo__icon-container {
    min-width: 16px;
}

.TransferInfo__icon {
    width: 8px;
    height: 8px;
    border: 4px solid #21201f;
    border-radius: 50%;
}

.TransferInfo__icon_red {
    border-color: #fa3e2c;
}

.TransferInfo__text {
    padding-inline-start: 0 !important;
    padding-inline-end: 0 !important;
}
