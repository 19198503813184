.root {
    background: var(--colorScheme-background);
    font-family: YS Text, serif;
    margin: 0 auto;
    max-width: 398px;
    padding: 20px 24px 28px;
}

.textCenter {
    text-align: center;
}

.body2Typography {
    font-size: 16px;
    line-height: 19;
}

.button {
    margin-top: var(--spacingUnits-l);
}

.phoneInputError {
    color: var(--colorScheme-textAttention);
}
