.container:not(.s) > *:not(:last-child) {
    margin-inline-end: var(--spacingUnits-s);
}

.container {
    display: flex;
}

.container.s {
    flex-direction: column;
}
