.icon {
    object-fit: contain;
    width: 48px;
    height: 47px;
}

.description {
    background: var(--colorScheme-bgMinor);
    border-radius: 24px;
}

.description.s {
    background: unset;
}

.note {
    max-width: 536px;
}

.title {
    max-width: 370px;
}

.title.s {
    max-width: none;
}
