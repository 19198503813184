.image {
    min-width: var(--components-slots-size-l);
    background-size: contain;
    background-repeat: no-repeat;
}

.image.ndd {
    background-image: url('../../claim/custom-controls/tariffs/images/pvz.png');
}

.image.cargo {
    background-image: url('../../claim/custom-controls/tariffs/images/cargo2.png');
}

.image.express {
    background-image: url('../../claim/custom-controls/tariffs/images/car.png');
}
