.select {
    width: 136px;
}

.select.wide {
    width: 165px;
}

.select.s {
    width: 100%;
}
