.footer {
    padding-top: calc(var(--spacingUnits-s) * 2);
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
}

.wrapper {
    padding-left: calc(var(--spacingUnits-s) * 8);
}

@media (min-width: 1280px) {
    .footer {
        max-width: 1280px;
    }
}

@media (min-width: 600px) {
    .footer {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media (max-width: 600px) {
    .footer {
        padding-bottom: calc(var(--spacingUnits-s) * 5);
    }

    .wrapper {
        padding-left: 0;
    }

    .wrapper > p {
        font-size: 14px;
        line-height: 20px;
    }
}
