.selectsRow {
    min-height: 40px;
}

.selectsRow:not(.s) > *:not(:last-child) {
    margin-right: var(--spacingUnits-s);
}

.selectWrapper.s {
    width: 100%;
}

.select.category {
    width: 186px;
}

.select.categoryType {
    width: 272px;
}

.select.dayType {
    width: 139px;
}

.select.s {
    width: 100%;
}

.segmentsWrapper {
    max-width: 614px;
}

.segmentsWrapper:not(.l) {
    flex: 1;
    margin-inline-start: var(--spacingUnits-s);
}

.segmentsWrapper.l:not(.s) {
    margin-block-start: var(--spacingUnits-l);
}

.segments {
    border-radius: 13px;
    overflow: hidden;
}
