.root {
    background: #fafafa;
    flex-grow: 1;
    min-height: 100vh;
    min-width: 1000px;
    overflow: hidden;
    padding: calc(var(--spacingUnits-s) * 8) 0;
}

.content {
    display: flex;
    flex-grow: 1;
}

@media (max-width: 600px) {
    .root {
        min-width: 370px;
        padding: calc(var(--spacingUnits-s) * 2) 0;
    }
}
