@keyframes shimmer {
    100% {
        mask-position: left;
    }
}

.numberIcon {
    margin: 0 !important;
}

.shimmer {
    mask: linear-gradient(90deg, #000 30%, rgba(255, 255, 255, 0.2), #000 70%) right/300% 100%;
    animation: shimmer 2s infinite;
}

.list {
    cursor: pointer;
}

.text {
    padding-inline-start: 0 !important;
    cursor: default;
}

.wrapper {
    transform: unset !important;
}
