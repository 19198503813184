.RouteInfo__icon {
    flex-shrink: 0;
}

.RouteInfo .amber-list-item:hover {
    cursor: default;
    background: inherit;
}

.RouteInfo .amber-list-item_header,
.RouteInfo .amber-list-item_header:hover {
    cursor: default;
    background: inherit;
}
