.wrapper {
    width: 100%;
    height: calc(var(--app-height) - 1px * var(--headerFullHeight));
}

.root {
    width: 649px;
    height: 256px;
    margin: auto;
}

.root.s {
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.image {
    width: 256px;
    height: 256px;
    flex-shrink: 0;
    background: url(https://yastatic.net/s3/taxi-corp-client/static-images/page-placeholder.png)
        no-repeat center;
    background-size: contain;
}
