.root {
    /** т.к при 600px значения изменяются, а в дизайне нет */
    --spacingUnits-xs: 4px;
    --spacingUnits-s: 8px;
    --spacingUnits-m: 16px;
    --spacingUnits-l: 24px;
    --spacingUnits-xl: 32px;

    z-index: 1;
    position: absolute;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(var(--app-height) - var(--content-top));
}

.root::-webkit-scrollbar {
    display: none;
}

.inner {
    margin: var(--spacingUnits-m);
    width: 800px;
    border-radius: 16px;
}

.inner.m {
    width: 534px;
}

.root.s {
    margin: calc(50vh - var(--content-top) + var(--inner-form-gap)) 0 0 0;
    overflow: unset;
    height: auto;
    width: 100%;
}

.inner.s {
    width: 100%;
    margin: 0;
    background: var(--colors-absolute-black);
    z-index: 1;
    padding: 0;
    border-radius: 40px;
    box-shadow: var(--shadows-topNormal);
}
