.titleWrapper.s {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-block-end: var(--spacingUnits-s);
}

.tabsWrapper {
    flex-grow: 0;
    margin-inline-start: auto;
}

.tabsWrapper.s {
    width: 100%;
}

.tab {
    min-width: 60px;
}

.subtitle {
    width: 90%;
}

.subtitle.s {
    width: auto;
}
