[id='faq'] {
    scroll-margin-top: var(--header-height);
}

.title {
    padding: 18px 0 18px 8px;
}

.title.s {
    padding-block-start: unset;
    padding-inline-start: unset;
}

.itemTitle:not(.s) {
    min-height: var(--components-slots-size-l);
}

.island.s {
    padding: 12px 0 12px 16px;
}

.itemAnswer.s {
    margin-block-start: 12px;
}

.itemAnswer:not(.s) {
    margin-block-end: 12px;
}

.wrapper {
    padding: var(--spacingUnits-s);
    padding-top: unset;
}

.accordionsWrapper {
    padding: var(--spacingUnits-s);
    border-radius: 20px;
    background-color: var(--colors-absolute-white);
}
