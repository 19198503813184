.imageContainer {
    bottom: calc(100% - 96px) !important;
}

.toggleIcon {
    background-repeat: no-repeat;
    background-size: contain;
    height: 37.2px;
    position: absolute;
    right: 44px;
    top: 40%;
    transform: translateX(-50%) rotate(18.05deg);
    width: 32.4px;
}

.toggleIcon.standard {
    background: url(../../assets/surge.svg) no-repeat center;
}

.toggleIcon.fixed {
    background: url(../../assets/no_surge.svg) no-repeat center;
}
