.header {
    z-index: 1;
}

.logoLink {
    display: flex;
}

.lang {
    font-size: 20px;
    font-weight: 500;
}
