.root {
    background: var(--colors-absolute-white);
    min-width: 1280px;
    line-height: var(--fonts-heights-caption1-loose);
    min-height: 100vh;
}

.root.s {
    min-width: 360px;
}

.container {
    background: white;
    width: 100%;
    outline: rgba(0, 0, 0, 0.08) solid 1px;
    position: relative;
    max-width: 1440px;
    min-width: 1280px;
    padding-inline-start: var(--spacingUnits-l);
}

.container.s {
    min-width: 360px;
    width: 100%;
    padding-inline-end: var(--spacingUnits-l);
}

.wrapper {
    background: var(--colors-absolute-white);
    margin-inline-start: calc(-1 * var(--spacingUnits-l) / 2);
    margin-inline-end: calc(-1 * var(--spacingUnits-l) / 2);
    width: 100%;
    max-width: 1416px;
    min-width: 1256px;
}

.wrapper.s {
    max-width: unset;
    min-width: unset;
    margin: unset;
}

.content {
    background: var(--colorScheme-background);
    padding-block-start: calc(var(--spacingUnits-xl) * 2);
    width: 80%;
    max-width: 1132px;
    min-width: 1004px;
    margin-inline-start: calc(-1 * var(--spacingUnits-l) / 2);
    margin-inline-end: calc(-1 * var(--spacingUnits-l) / 2);
    padding-inline-end: calc(var(--spacingUnits-l) / 2);
    box-sizing: content-box;
    padding-inline-start: var(--spacingUnits-l);
}

.content.s {
    width: 100%;
    min-width: unset;
    max-width: unset;
    padding-inline-start: unset;
    padding-inline-end: unset;
    margin: unset;
}

.sidebarWrapper {
    padding-inline-start: 0;
    padding-inline-end: calc(var(--spacingUnits-l) / 2);
    width: 20%;
    max-width: 283px;
    min-width: 251px;
}
