.leftCard {
    background-color: #f7f2e1;
    background-image: url(../assets/eatsIcon.svg);
    background-position: bottom right;
    background-repeat: no-repeat;
    border-radius: var(--spacingUnits-m);
    flex: 1 1 50%;
    margin-right: var(--spacingUnits-s);
    padding: calc(var(--spacingUnits-s) * 1.5);
}

.rightCard {
    background: url(../assets/orderCardPaper.png);
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: var(--spacingUnits-m);
    height: 104px;
    padding: calc(var(--spacingUnits-s) * 1.5);
    width: 143px;
}

.link {
    color: var(--colorScheme-text);
    display: block;
    height: 100%;
    text-decoration: none;
}

.box {
    background: rgba(235, 234, 232, 1);
    border-radius: var(--spacingUnits-m);
    min-height: 56px;
}

.link2 {
    color: var(--colorScheme-text);
    display: block;
    margin-top: -8px;
    text-decoration: none;
}
