.root {
    width: 100vw;
    height: 100vh;
    background-color: var(--colorScheme-background);
    overflow: auto;
}

.content {
    min-height: 100%;
}

.account {
    width: 100%;
    max-width: 500px;
}

.image {
    width: 256px;
    height: 256px;
    align-self: center;
}

.header {
    margin-top: 0;
}

.authButtonCaption {
    max-width: 100%;
}

.authButtonCaptionText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
