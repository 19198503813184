.item {
    background-color: rgba(235, 234, 232, 1);
    height: fit-content;
    margin-block-end: var(--spacingUnits-m);
    padding: 20px var(--spacingUnits-m);
    border-radius: 24px;
    flex: 1;
}

.item.s {
    background-color: unset;
    margin-block-end: unset;
    border-radius: unset;
    padding: unset;
}
