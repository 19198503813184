.root {
    color: var(--colorScheme-textMinor);
    padding-top: 72px;
}

.root.s {
    padding: 12px 16px;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
}
