.circle {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fonts-sizes-body2);
    margin-inline-start: 4px;
    margin-inline-end: 16px;
    background-color: var(--colors-warmGray-600);
}

.circle.red {
    background-color: var(--colors-red-normal);
}

.number {
    color: var(--colors-absolute-white);
    font-weight: var(--fonts-weights-medium);
}
