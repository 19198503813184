.CargoOrderSideHeaderV2 {
    &__highlight {
        position: absolute;
        width: 8px;
        height: calc(100% + 6px);
        left: 16px;
        top: 36px;

        &_type {
            &_source {
                background: #FC8E62;
            }

            &_destination {
                background: #42C6FA;
            }
        }
    }
    &_mini {
        height 50px;
        .CargoOrderSideHeaderV2__label {
            font-size: $unit * 2;
        }
        .CargoOrderSideHeaderV2__highlight {
            position: absolute;
            width: 5px;
            height: 21px;
            top: 29px;
            left: 4px;

            &_type {
                &_source {
                    background: #FC8E62;
                }

                &_destination {
                    background: #42C6FA;
                }
            }
        }
        &:last-child {
            .CargoOrderSideHeaderV2__highlight {
                display: none;
            }
        }
    }
}
