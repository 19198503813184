.root {
    background-color: var(--colorScheme-background);
}

.spinner {
    width: 32px;
    height: 32px;
    margin-block-start: 28px;
    animation: spin 1.2s infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
