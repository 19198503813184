.wrapper {
    position: absolute;
    right: 0;
    text-align: right;
    top: 105px;
    flex-grow: 0;
    max-width: 41%;
    flex-basis: 41%;
}

.wrapperMobile {
    max-width: 83%;
    flex-basis: 83%;
}

.mainImage {
    max-width: 580px;
}

.icon {
    position: absolute;
}

.goIcon {
    height: 75px;
    right: 0;
    top: -10%;
}

@media (max-width: 600px) {
    .root {
        position: relative;
        height: 158px;
    }

    .wrapper {
        top: 0;
        position: relative;
        transform: none !important;
    }

    .mainImage {
        max-height: 274px;
    }

    .icon {
        position: absolute;
    }

    .goIcon {
        height: 65px;
        bottom: 96px;
        left: -20%;
        right: unset;
        top: unset;
        z-index: 1;
    }
}
