.image {
    background: url(../assets/no_tariff.svg) no-repeat center;
    height: 300px;
    margin: 0 auto;
    margin-top: calc(var(--spacingUnits-xl) * 2);
    width: 300px;
}

.typography {
    max-width: 270px;
    text-align: center;
}
