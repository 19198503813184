.row {
    flex-direction: row;
}

.island.island.s {
    margin-block-end: 3px;
}

@media (max-width: 800px) {
    .tariffs {
        overflow-x: scroll;
        padding-bottom: 4px;
    }

    .tariffs::-webkit-scrollbar {
        display: none;
    }
}
