.root {
    max-width: 522px;
}

.paper {
    background-color: var(--colors-warmGray-100);
    box-shadow: none;
    border-radius: 20px !important;
}

.item:not(:last-child) {
    border-bottom: var(--components-listItems-borderDivider);
}
