.DatePicker {
    &.amber-input_theme_underline {
        width: $unit * 12;

        input.amber-input__control {
            text-align: center;
        }
    }

    // TODO: застилизовать крестик в самом амбере
    & + button.react-datepicker__close-icon:after {
        background-color: transparent;
        font-size: 18px;
        color: black;
        line-height: 13px;
    }
}
