.subtitle {
    color: var(--colors-blue-dark);
    display: flex;
    align-items: center;
}

.chevron {
    margin-top: 1px;
}

[dir='rtl'] .chevron {
    transform: rotateZ(180deg);
}

.activeChevron {
    transform: rotateZ(90deg);
    margin-top: 1px;
}

.content {
    display: none;
}

.content.isActive {
    display: block;
}
