.root {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

@media (max-width: 600px) {
    .root {
        flex-direction: column;
    }
}
