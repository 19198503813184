.auto {
    margin-inline-start: auto;
}

.island.island.island {
    z-index: 1;
    margin-bottom: 0;
    overflow: hidden;
    border-radius: var(--spacingUnits-l);
    box-shadow: var(--shadows-bottomNormal);
}

.island.island.island:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
}

.islandInner.islandInner {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.island.island.island.s {
    border-radius: var(--spacingUnits-l);
    margin-block-end: 3px;
}

.islandInner.islandInner.s {
    border-radius: var(--spacingUnits-l);
}

.islandContent {
    padding: var(--spacingUnits-m);
}

.islandContent.s {
    padding-inline-start: unset;
    padding-inline-end: unset;
}

.switcherWrapper.s {
    overflow-x: auto;
    margin-inline-start: calc(-1 * var(--spacingUnits-m));
    margin-inline-end: calc(-1 * var(--spacingUnits-m));
    padding-inline-start: calc(2 * var(--spacingUnits-m));
    padding-inline-end: calc(2 * var(--spacingUnits-m));
}

.switcherWrapper.s::-webkit-scrollbar {
    display: none;
}
