.widget {
    margin: 0 auto;
    padding-top: 108px;
}

.widget * {
    box-sizing: border-box;
    font-family: YS Text, serif !important;
}

@media (max-width: 600px) {
    .widget {
        display: flex !important;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0 auto;
        min-height: inherit;
        padding-top: 70px;
    }
}
